{
    "Heisse Preise": "Heisse Preise",
    "page_description": "Nicht-kommerzielles Open-Source-Projekt um KonsumentInnen es zu ermöglichen, die günstigste Variante eines Produktes im Handel ausfindig zu machen.",
    "Einstellungen": "Einstellungen",
    "Impressum": "Impressum",
    "Logs": "Logs",
    "Historische Daten von": "Historische Daten von",
    "Alle Angaben ohne Gewähr, Irrtümer vorbehalten.": "Alle Angaben ohne Gewähr, Irrtümer vorbehalten.",
    "Markennamen und Warenzeichen sind Eigentum der jeweiligen Inhaber.": "Markennamen und Warenzeichen sind Eigentum der jeweiligen Inhaber.",
    "Suche": "Suche",
    "Preisänderungen": "Preisänderungen",
    "Warenkörbe": "Warenkörbe",

    "Noch keine Produkte im Warenkorb.": "Noch keine Produkte im Warenkorb.",
    "Produkte suchen und mit '+' zum Warenkorb hinzufügen.": "Produkte suchen und mit '+' zum Warenkorb hinzufügen.",
    "Filtern...": "Filtern...",
    "(min. 3 Zeichen)": "(min. 3 Zeichen)",
    "Produkt hinzufügen...": "Produkt hinzufügen...",

    "Neuer Warenkorb": "Neuer Warenkorb",
    "Exportieren": "Exportieren",
    "Importieren": "Importieren",

    "Medieninhaber": "Medieninhaber",
    "Kontakt": "Kontakt",
    "Adresse": "Adresse",
    "Diese nicht-kommerzielle Seite dient KonsumentInnen dazu, Preise von Produkten im Lebensmittelhandel vergleichen zu können.": "Diese nicht-kommerzielle Seite dient KonsumentInnen dazu, Preise von Produkten im Lebensmittelhandel vergleichen zu können.",

    "Video Anleitung": "Video Anleitung",
    "Text Anleitung": "Text Anleitung",
    "Medienberichte": "Medienberichte",
    "Produktsuche": "Produktsuche",

    "Radio & Fernsehen": "Radio & Fernsehen",
    "Print & Online": "Print & Online",

    "CartsList_Name": "Name",
    "CartsList_Produkte": "Produkte",
    "CartsList_Preis": "Preis",

    "CartsList_Preisänderungen": "Preisänderungen",
    "CartsList_Teilen": "Teilen",
    "CartsList_JSON": "JSON",
    "CartsList_Löschen": "Löschen",

    "ItemsChart_Keine Daten ausgewählt": "Keine Daten ausgewählt",
    "ItemsChart_Preissumme Gesamt": "Preissumme Gesamt",
    "ItemsChart_Preissumme Ketten": "Preissumme Ketten",
    "ItemsChart_Nur heutige Preise": "Nur heutige Preise",
    "ItemsChart_Änderung in % seit": "Änderung in % seit",
    "ItemsChart_Änderung in % seit {{date}}": "Änderung in % seit {{date}}",
    "ItemsChart_Preissumme {{s}}": "Preissumme {{s}}",

    "ItemsFilter_Produkte suchen...": "Produkte suchen...",
    "ItemsFilter_Filter anzeigen/ausblenden": "Filter anzeigen/ausblenden",
    "ItemsFilter_Alle": "Alle",
    "ItemsFilter_Datum": "Datum",
    "ItemsFilter_Billiger seit letzter Änderung": "Billiger seit letzter Änderung",
    "ItemsFilter_Nur Diskont-Eigenmarken": "Nur Diskont-Eigenmarken",
    "ItemsFilter_Nur Bio": "Nur Bio",
    "ItemsFilter_Exaktes Wort": "Exaktes Wort",
    "ItemsFilter_Preis €": "Preis €",
    "ItemsFilter_Teurer": "Teurer",
    "ItemsFilter_Billiger": "Billiger",

    "ItemsList_Resultate": "Resultate",
    "ItemsList_Diagramm": "Diagramm",
    "ItemsList_Verkaufspreis": "Verkaufspreis",
    "ItemsList_Mengenpreis": "Mengenpreis",
    "ItemsList_Sortieren": "Sortieren",
    "ItemsList_Preis aufsteigend": "Preis aufsteigend",
    "ItemsList_Preis absteigend": "Preis absteigend",
    "ItemsList_Menge aufsteigend": "Menge aufsteigend",
    "ItemsList_Menge absteigend": "Menge absteigend",
    "ItemsList_Kette &amp; Name": "Kette &amp; Name",
    "ItemsList_Namensähnlichkeit": "Namensähnlichkeit",
    "ItemsList_Kette": "Kette",
    "ItemsList_Name": "Name",
    "ItemsList_Preis": "Preis",

    "Cart_Teilen": "Teilen",
    "Cart_Speichern": "Speichern",
    "Cart_Warenkorb {{name}}": "Warenkorb {{name}}",
    "Cart_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu speichernden Warenkorb eingeben": "Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu speichernden Warenkorb eingeben",
    "Cart_Warenkorb '{{name}}' existiert nicht.": "Warenkorb '{{name}}' existiert nicht.",
    "Cart_Artikel": "Artikel",

    "Carts_Name für Warenkorb eingeben:": "Name für Warenkorb eingeben:",
    "Carts_Warenkorb mit Namen '{{name}}' existiert bereits": "Warenkorb mit Namen '{{name}}' existiert bereits",
    "Carts_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu importierenden Warenkorb eingeben": "Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu importierenden Warenkorb eingeben",

    "Settings_Vorselektierte Ketten": "Vorselektierte Ketten",
    "Settings_Start-Datum für Diagramme": "Start-Datum für Diagramme",
    "Settings_Diagramm Typ": "Diagramm Typ",
    "Settings_Stufen": "Stufen",
    "Settings_Linien": "Linien",
    "Settings_Nur verfügbare Produkte anzeigen": "Nur verfügbare Produkte anzeigen",
    "Settings_Diagramm immer anzeigen (wenn verfügbar)": "Diagramm immer anzeigen (wenn verfügbar)",
    "Settings_Suche immer anzeigen (wenn verfügbar)": "Suche immer anzeigen (wenn verfügbar)"
}
