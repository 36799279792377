{
    "Heisse Preise": "Zlevněno?",
    "page_description": "Nekomerční open source projekt umožňující spotřebitelům najít nejlevnější verzi produktu v obchodech.",
    "Einstellungen": "Nastavení",
    "Impressum": "Impressum",
    "Logs": "Logy",
    "Historische Daten von": "Historická data od",
    "Alle Angaben ohne Gewähr, Irrtümer vorbehalten.": "Veškeré informace jsou poskytovány bez záruky, chyby vyhrazeny.",
    "Markennamen und Warenzeichen sind Eigentum der jeweiligen Inhaber.": "Názvy značek a ochranné známky jsou majetkem příslušných vlastníků.",
    "Suche": "Vyhledávání",
    "Preisänderungen": "Změny cen",
    "Warenkörbe": "Nákupní vozíky",

    "Noch keine Produkte im Warenkorb.": "Nákupní košík je prázdný.",
    "Produkte suchen und mit '+' zum Warenkorb hinzufügen.": "Vyhledejte produkty a přidejte je do nákupního košíku pomocí '+'.",
    "Filtern...": "Filtr...",
    "(min. 3 Zeichen)": "(alespoň 3 znaky)",
    "Produkt hinzufügen...": "Přidat produkt...",

    "Neuer Warenkorb": "Nový nákupní košík",
    "Exportieren": "Export",
    "Importieren": "Import",

    "Medieninhaber": "Majitel",
    "Kontakt": "Kontakt",
    "Adresse": "Adresa",
    "Diese nicht-kommerzielle Seite dient KonsumentInnen dazu, Preise von Produkten im Lebensmittelhandel vergleichen zu können.": "Tato nekomerční stránka umožňuje spotřebitelům porovnávat ceny produktů v obchodě s potravinami.",

    "Video Anleitung": "Video instrukce (německy)",
    "Text Anleitung": "Textové instrukce (německy)",
    "Medienberichte": "Napsali o nás",
    "Produktsuche": "Vyhledávání produktů",

    "Radio & Fernsehen": "Rádio & Televize",
    "Print & Online": "Tisk & Online",

    "CartsList_Name": "Název",
    "CartsList_Produkte": "Produkt",
    "CartsList_Preis": "Cena",

    "CartsList_Preisänderungen": "Změny cen",
    "CartsList_Teilen": "Sdílet",
    "CartsList_JSON": "JSON",
    "CartsList_Löschen": "Smazat",

    "ItemsChart_Keine Daten ausgewählt": "Nejsou vybrána žádná data",
    "ItemsChart_Preissumme Gesamt": "Celková cena",
    "ItemsChart_Preissumme Ketten": "Cena řetězce",
    "ItemsChart_Nur heutige Preise": "Cena dnes",
    "ItemsChart_Änderung in % seit": "Změna v % od",
    "ItemsChart_Änderung in % seit {{date}}": "Změna v % od {{date}}",
    "ItemsChart_Preissumme {{s}}": "Cena {{s}}",

    "ItemsFilter_Produkte suchen...": "Vyhledávání produktů...",
    "ItemsFilter_Filter anzeigen/ausblenden": "Zobrazit/skrýt filtry",
    "ItemsFilter_Alle": "Vše",
    "ItemsFilter_Datum": "Datum",
    "ItemsFilter_Billiger seit letzter Änderung": "Levnější od poslední změny",
    "ItemsFilter_Nur Diskont-Eigenmarken": "Pouze privátní značky",
    "ItemsFilter_Nur Bio": "Pouze bio",
    "ItemsFilter_Exaktes Wort": "Přesná slova",
    "ItemsFilter_Preis €": "Cena €",
    "ItemsFilter_Teurer": "Dražší",
    "ItemsFilter_Billiger": "Levnější",

    "ItemsList_Resultate": "Výsledky",
    "ItemsList_Diagramm": "Graf",
    "ItemsList_Verkaufspreis": "Prodejní cena",
    "ItemsList_Mengenpreis": "Měrná cena",
    "ItemsList_Sortieren": "Seřadit podle",
    "ItemsList_Preis aufsteigend": "Cena vzestupně",
    "ItemsList_Preis absteigend": "Cena sestupně",
    "ItemsList_Menge aufsteigend": "Množství vzsetupně",
    "ItemsList_Menge absteigend": "Množství sestupně",
    "ItemsList_Kette &amp; Name": "Řetězec &amp; název",
    "ItemsList_Namensähnlichkeit": "Podobnost jména",
    "ItemsList_Kette": "Řetězec",
    "ItemsList_Name": "Název",
    "ItemsList_Preis": "Cena",

    "Cart_Teilen": "Detail",
    "Cart_Speichern": "Uložit",
    "Cart_Warenkorb {{name}}": "Nákupní košík {{name}}",
    "Cart_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu speichernden Warenkorb eingeben": "Nákupní košík '{{name}}' již existuje. Zadejte prosím jiný název",
    "Cart_Warenkorb '{{name}}' existiert nicht.": "Nákupní košík '{{name}}' neexistuje.",
    "Cart_Artikel": "Položka",

    "Carts_Name für Warenkorb eingeben:": "Zadejte název nákupního košíku:",
    "Carts_Warenkorb mit Namen '{{name}}' existiert bereits": "Nákupní košík se jménem '{{name}}' již existuje",
    "Carts_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu importierenden Warenkorb eingeben": "Nákupní košík '{{name}}' již existuje. Zadejte prosím jiný název pod který se nákupní košík importuje",

    "Settings_Vorselektierte Ketten": "Předvybrané řetězce",
    "Settings_Start-Datum für Diagramme": "Počáteční datum pro grafy",
    "Settings_Diagramm Typ": "Typ grafu",
    "Settings_Stufen": "Schodový",
    "Settings_Linien": "Čárový",
    "Settings_Nur verfügbare Produkte anzeigen": "Zobrazit pouze dostupné produkty",
    "Settings_Diagramm immer anzeigen (wenn verfügbar)": "Vždy zobrazovat graf (pokud je k dispozici)",
    "Settings_Suche immer anzeigen (wenn verfügbar)": "Vždy zobrazit vyhledávání (je-li k dispozici)"
}
